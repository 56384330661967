// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiEmpresa: 'https://homolog.viarapidaempresa.sp.gov.br/empresa-api',
  apiAdmin: 'https://homolog.viarapidaempresa.sp.gov.br/modulo-adm-api-sustentacao',
  linkGovBr: 'https://homolog.viarapidaempresa.sp.gov.br/loginApi/getTokenService?codeRedirectTo=2',
  linkServidores: 'https://homologacao.vre.jucesp.sp.gov.br/IntegradorPaulistaAdmin/seguranca/login',
  production: false,
  linkPDFs: 'https://homolog.viarapidaempresa.sp.gov.br/Documentacao/Manuais/HML',
  linkConsultaPublica: 'https://homologacao.vre.jucesp.sp.gov.br/IntegradorPaulista/ConsultaPublica'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
