import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutenticidadeDigitalService {

  constructor(
    private http: HttpClient
  ) { }

  getDadosAutenticidade(nire: any): Promise<any> {
    return this.http.get(environment.apiEmpresa + `/mod-admin/${nire}/consulta-autenticidade`)
    .toPromise()
    .then(response => response);
  }
}
