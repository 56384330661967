import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class MunicipiosConveniadosService {

  capitals: string = '/assets/geojson/saopaulo.geo.json';
  urlMunicipios: string = '../../assets/geojson/geojs_sao_paulo_min.json';

  constructor(private http: HttpClient, private httpClientService: HttpClientService) { }

  getStateShapes() {
    return this.httpClientService.get(this.urlMunicipios);
  }

  // getStateShapes(): Observable<any> {
  //   return this.http.get(this.urlMunicipios);
  // }

  getListarMunicipio(): Promise<any> {
    return this.http.get(environment.apiAdmin + `/municipio-conveniado`)
    .toPromise()
    .then(response => response);
  }

}
