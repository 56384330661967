import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComunicadoService } from 'src/app/services/comunicado.service';
import { ErrorService } from 'src/app/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-barra-gov',
  templateUrl: './barra-gov.component.html',
  styleUrls: ['./barra-gov.component.scss']
})
export class BarraGovComponent implements OnInit, AfterViewInit {

  visualizouModal: any;

  constructor(

  ) { }

  ngOnInit() { 

  }

  ngAfterViewInit(): void {

    //função para visualizar modal apenas uma vez

    //setTimeout(() => this.openModalAvisos());

    // if(!localStorage.getItem('visualizouModal')){
    //   localStorage.setItem('visualizouModal', 'true');
    // }
    // this.visualizouModal = localStorage.getItem('visualizouModal')
    // if(this.visualizouModal == 'true'){
    //   this.openModalAvisos();
    //   localStorage.setItem('visualizouModal', 'false');
    // }

  }

}
