import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-abrir-sua-empresa',
  templateUrl: './como-abrir-sua-empresa.component.html',
  styleUrls: ['./como-abrir-sua-empresa.component.scss']
})
export class ComoAbrirSuaEmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
