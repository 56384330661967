import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private http: HttpClient) { }

  get(url: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + this.getToken()
      })
    };

    return this.http.get<any>(url, httpOptions);
  }

  put(url: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //Authorization: 'Bearer ' + this.getToken()
      })
    };

    return this.http.put<any>(url, data, httpOptions);
  }

  post(url: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //Authorization: 'Bearer ' + this.getToken()
      })
    };

    return this.http.post<any>(url, data, httpOptions);
  }

  delete(url: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //Authorization: 'Bearer ' + this.getToken()
      })
    };

    return this.http.delete<any>(url, httpOptions);
  }

  patch(url: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //Authorization: 'Bearer ' + this.getToken()
      })
    };

    return this.http.patch<any>(url, data, httpOptions);
  }

}
