import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'app-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit, AfterViewInit   {

  @ViewChild('owl', { static: true }) owl:ElementRef;

  ngAfterViewInit(): void {

      $(this.owl.nativeElement).owlCarousel(
        {
          navigation : true,
          loop: true,
          margin: 10,
          autoplay: true,
          autoWidth: false,
          autoplayTimeout: 2000,
          autoplayHoverPause: true,
          nav: true,
          responsive: {
            0: {
              items: 1
            },
            411: {
              items: 1
            },
            768: {
              items: 1
            },
            960: {
              items: 3
            },
            1200: {
              items: 3
            },
            1920: {
              items: 4
            }
          },
          navText: ["<i class='fas fa-chevron-left fa-2x text-black-50' aria-hidden='true'></i>",
            "<i class='fas fa-chevron-right fa-2x text-black-50' aria-hidden='true'></i>"
          ]
        }

      );
}

  constructor() { }

  ngOnInit() {
  }

}
