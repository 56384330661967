import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [NgbPopoverConfig] 
})
export class MenuComponent implements OnInit {

 @Input() dadosComunicado: any;

 bloqueioLogin: boolean;

  constructor(
    public router:Router,
    private config: NgbPopoverConfig
    ) { 
      config.triggers = 'hover';
      config.placement = 'bottom';
    }

  ngOnInit() {
  }

  ngOnChanges () {
    // Verifica se o input veio populado antes de usar
    if (this.dadosComunicado) {
        this.bloqueioLogin = this.dadosComunicado.bloqueioLogin;
    } else {
      this.bloqueioLogin = false;
    }
  }

  getRoute(){
    if (this.router.url === '/home' || this.router.url === '/home#sistemasIntegrados'){
     return "header-home-menu";
    }
 }

  linkGovBr(){
    window.open(environment.linkGovBr, '_blank')
  }

  linkConsultaPublica(){
    window.open(environment.linkConsultaPublica, '_blank')
  }


}
