import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sistemas-integrados',
  templateUrl: './sistemas-integrados.component.html',
  styleUrls: ['./sistemas-integrados.component.scss']
})
export class SistemasIntegradosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

}
