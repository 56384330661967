import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-avisos-home',
  templateUrl: './modal-avisos-home.component.html',
  styleUrls: ['./modal-avisos-home.component.scss']
})
export class ModalAvisosHomeComponent implements OnInit {

  @Input() dadosComunicado: any;

  codbase64: any;
  contentType: any;

  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    if(this.dadosComunicado.tipoMensagem == 3) {
      this.codbase64 = `data:${this.dadosComunicado.contentType};base64,${this.dadosComunicado.imagemDownload}`;
    }

  }

}
