import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './header/menu/menu.component';
import { BarraGovComponent } from './header/barra-gov/barra-gov.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    BarraGovComponent,
    MenuComponent,
    FooterComponent
  ],

  imports: [
    CommonModule,
    RouterModule, 
    NgbModule,
    NgbPopoverModule
  ],

  exports: [
    BarraGovComponent,
    MenuComponent,
    FooterComponent
  ]
})
export class CoreModule { }
