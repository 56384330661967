import { Component, OnInit } from '@angular/core';
import { AutenticidadeDigitalService } from '../services/autenticidade-digital.service';

@Component({
  selector: 'app-autenticidade-digital',
  templateUrl: './autenticidade-digital.component.html',
  styleUrls: ['./autenticidade-digital.component.scss']
})
export class AutenticidadeDigitalComponent implements OnInit {

  nireDigitado: any;
  autenticidade: any;
  mensagemErro: boolean = false;
  exibeResponse: boolean = false;
  constituicao: any;
  procuracao: Array<any> = [];
  emancipacao: Array<any> = [];

  constructor(
    private AutenticidadeService: AutenticidadeDigitalService
  ) { }

  ngOnInit() {
  }

  getAutenticidadeDigital(){
    this.nireDigitado = this.nireDigitado.trim();
    this.mensagemErro = false;
    this.AutenticidadeService.getDadosAutenticidade(this.nireDigitado)
    .then(res => {
      this.autenticidade = res;
      this.exibeResponse = true;
      this.constituicao = res.constituicao.split(':')[1];
      this.procuracao = res.procuracoes;
      this.emancipacao = res.emancipacoes;
    })
    .catch(error => {
      this.mensagemErro = true;
      this.exibeResponse = false;
    });
  }

}
