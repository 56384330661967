import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID} from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbActiveModal, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SistemasIntegradosComponent } from './sistemas-integrados/sistemas-integrados.component';
import { ComoAbrirSuaEmpresaComponent } from './como-abrir-sua-empresa/como-abrir-sua-empresa.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { ParceirosComponent } from './parceiros/parceiros.component';
import { ConhecaIntegradorComponent } from './conheca-integrador/conheca-integrador.component';
import { PerguntasFrequentesComponent } from './perguntas-frequentes/perguntas-frequentes.component';
import { InformacoesUteisComponent } from './informacoes-uteis/informacoes-uteis.component';
import { ContatoComponent } from './contato/contato.component';
import { ManuaisComponent } from './manuais/manuais.component';
import { ModalViewVideoYoutubeComponent } from './shared/modal-view-video-youtube/modal-view-video-youtube.component';
import { AutenticidadeDigitalComponent } from './autenticidade-digital/autenticidade-digital.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask'
import { ModalAvisosHomeComponent } from './shared/modal-avisos-home/modal-avisos-home.component';
import { BalcaoUnicoComponent } from './balcao-unico/balcao-unico.component';
import { MapaMunicipiosConveniadosComponent } from './mapa-municipios-conveniados/mapa-municipios-conveniados.component';
import { CommonModule } from '@angular/common';

@NgModule({
   declarations: [
      AppComponent,
      HomeComponent,
      SistemasIntegradosComponent,
      NoticiasComponent,
      ParceirosComponent,
      ConhecaIntegradorComponent,
      PerguntasFrequentesComponent,
      InformacoesUteisComponent,
      ContatoComponent,
      ManuaisComponent,
      ModalViewVideoYoutubeComponent,
      AutenticidadeDigitalComponent,
      ModalAvisosHomeComponent,
      ComoAbrirSuaEmpresaComponent,
      BalcaoUnicoComponent,
      MapaMunicipiosConveniadosComponent
   ],
   entryComponents: [
      ModalViewVideoYoutubeComponent,
      ModalAvisosHomeComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      CommonModule,
      CoreModule,
      NgbModule,
      NgbPopoverModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      NgxMaskModule.forRoot(),
      ToastrModule.forRoot()
   ],
   exports: [
      RouterModule,
      ModalViewVideoYoutubeComponent,
      ModalAvisosHomeComponent

   ],
   providers: [
      NgbActiveModal
   ],
   bootstrap: [
      AppComponent
   ],
   schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
