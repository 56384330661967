import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ErrorService {

  constructor(
    private toastyService: ToastrService
  ) { }

  error(errorResponse: any) {
    let msg: Array<any> = [];
    let mensagemErro;

    try {

      if (errorResponse.error && !errorResponse.error.length) {
        if (errorResponse.error.message) {
          mensagemErro = errorResponse.error.message.split('\n');
        }

        if (errorResponse.error.mensagem) {
          mensagemErro = errorResponse.error.mensagem.split('\n');
        }

        if (errorResponse.error.descricao) {
          mensagemErro = errorResponse.error.descricao.split('\n');
        }

        for (let i = 0; i < mensagemErro.length; i++) {
          this.toastyService.error(mensagemErro[i], '', {
            closeButton: true,
            timeOut: 10000
          });
        }
      } else if(errorResponse.error){
        errorResponse.error.forEach((element:any) => {
          this.toastyService.error(element.message, '', {
            closeButton: true,
            timeOut: 10000
          });
        });
      } else {

        if (errorResponse._body) {
          msg.push(JSON.parse(errorResponse._body).message);
        }

        if (errorResponse instanceof HttpErrorResponse
            && errorResponse.status >= 400
            && errorResponse.status <= 499
            && errorResponse.error
            && errorResponse.error.length !== 0) {

          if (errorResponse.status === 403) {
            msg.push('Você não tem permissão para executar essa ação');
          }

          try {

            for (let i = 0; i <= errorResponse.error.length; i++) {
              msg.push(errorResponse.error[i].message);
            }

            msg = errorResponse.error;
          } catch (e) { }

          console.error('Ocorreu um erro', errorResponse);

        } else {
          if (msg == null) {
            msg.push('Erro ao processar serviço remoto. Tente novamente.');
            console.error('Ocorreu um erro', errorResponse);
          }
        }

        for (let i = 0; i < msg.length; i++) {
          this.toastyService.error(msg[i], '', {
            closeButton: true,
            timeOut: 10000
          });
        }
      }

    } catch {
      this.toastyService.error('Ocorreu um erro inesperado. Tente novamente!', '', {
        closeButton: true,
        timeOut: 10000
      });
    }


  }

}
