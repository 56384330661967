import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conheca-integrador',
  templateUrl: './conheca-integrador.component.html',
  styleUrls: ['./conheca-integrador.component.scss']
})
export class ConhecaIntegradorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
