import { Component, OnInit, Input, Output, ViewChild, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-view-video-youtube',
  templateUrl: './modal-view-video-youtube.component.html',
  styleUrls: ['./modal-view-video-youtube.component.scss']
})

export class ModalViewVideoYoutubeComponent implements OnInit {

  urlYoutube: string;
  titleYoutube: string;
  safeSrc: SafeResourceUrl;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { 

    }

  ngOnInit() {
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlYoutube);
  }


}
