import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ConhecaIntegradorComponent } from './conheca-integrador/conheca-integrador.component';
import { PerguntasFrequentesComponent } from './perguntas-frequentes/perguntas-frequentes.component';
import { InformacoesUteisComponent } from './informacoes-uteis/informacoes-uteis.component';
import { ContatoComponent } from './contato/contato.component';
import { ManuaisComponent } from './manuais/manuais.component';
import { AutenticidadeDigitalComponent } from './autenticidade-digital/autenticidade-digital.component';
import { BalcaoUnicoComponent } from './balcao-unico/balcao-unico.component';
import { MapaMunicipiosConveniadosComponent } from './mapa-municipios-conveniados/mapa-municipios-conveniados.component';

const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'conheca-integrador-estadual', component: ConhecaIntegradorComponent },
  { path: 'perguntas-frequentes', component: PerguntasFrequentesComponent },
  { path: 'legislacao', component: InformacoesUteisComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'tutoriais', component: ManuaisComponent },
  { path: 'mapa-municipios-conveniados', component: MapaMunicipiosConveniadosComponent },
  { path: 'balcao-unico', component: BalcaoUnicoComponent },

  // PÁGINA DE AUTENTICIDADE DE REGISTRO DIGITAL
  { path: 'autenticidade-digital', component: AutenticidadeDigitalComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
