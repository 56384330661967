import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-balcao-unico',
  templateUrl: './balcao-unico.component.html',
  styleUrls: ['./balcao-unico.component.scss']
})
export class BalcaoUnicoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
