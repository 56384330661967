import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalViewVideoYoutubeComponent } from '../shared/modal-view-video-youtube/modal-view-video-youtube.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-perguntas-frequentes',
  templateUrl: './perguntas-frequentes.component.html',
  styleUrls: ['./perguntas-frequentes.component.scss']
})
export class PerguntasFrequentesComponent implements OnInit {

  linkPDFs: string;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.linkPDFs = environment.linkPDFs;
  }

  openModalViewYoutube(urlYoutube: string, titleYoutube: string) {
    const modalPreviewYoutube = this.modalService.open(ModalViewVideoYoutubeComponent, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
    modalPreviewYoutube.componentInstance.urlYoutube = urlYoutube;
    modalPreviewYoutube.componentInstance.titleYoutube = titleYoutube;
  }  

}
