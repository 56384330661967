import { Component, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComunicadoService } from './services/comunicado.service';
import { ErrorService } from './services/error.service';
import { ModalAvisosHomeComponent } from './shared/modal-avisos-home/modal-avisos-home.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  localExibicao: any;
  tipoPortal: any;
  dadosComunicado: any;

  constructor(
    private router: Router,
    private service: ComunicadoService,
    private errorService: ErrorService,
    private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.loadComunicado();
    }

    loadComunicado() {
      this.localExibicao = "AREA_NAO_LOGADA";
      this.tipoPortal = "INTEGRADOR"
      this.service.getComunicado(this.localExibicao,this.tipoPortal)
        .then((res) => {
          if(res != ""){
            this.dadosComunicado = res;
            if (this.dadosComunicado.statusComunicado == 'ATIVO') {
              this.openModalAvisos(this.dadosComunicado);
            } 
          }
        })
        .catch((erro) => {
          this.errorService.error(erro);
        });
    }

    exibeMenus(): boolean {
      return this.router.url !== '/autenticidade-digital';
    }  

    openModalAvisos(dadosComunicado: any){
      const modal = this.modalService.open(ModalAvisosHomeComponent,  
      {
        size: 'lg', 
        centered: true, 
        backdrop: 'static', 
        keyboard: false 
      });
      modal.componentInstance.dadosComunicado = this.dadosComunicado;
    }

  }